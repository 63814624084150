.brd_btm {
    border-bottom: 1px solid #292d4c;

    &:last-child {
        border-bottom: none;
    }
}
.wallet_sec {

      
    & .wallet_balance_sec {

        & .rupee_icon{
        
            & img{
                height: 18px;
            }
            }
    }

    & .deposit_row {
        & .nav-tabs {
            .nav-link {
                color: #6a798c;
                font-weight: 600;

                &.active {
                    background: #0e95fa;
                    color: #fff;
                }
            }
        }

        & .img_list {
            & li {
                margin-left: -10px;

                & img {
                    width: 23px;
                    height: 23px;
                    object-fit: contain;
                }
            }
        }
    }
    & .select_drop {
        & .dropdown-toggle::after {
            content: "\f078";
            font-family: "FONTAWESOME";
            border: none !important;
            color: #fff;
            font-size: 12px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #202732;
            border-radius: 50%;
        }
        & #defaultDropdown{
            & img{
                height: 25px;
            }
        }

        & .dropdown-menu {
            & .searchInpDrop {
                & input {
                    background-color: #2e3443;
                    border: 1px solid #93a6c4;
                    border-radius: 5px;
                    height: 40px;
                    color: white;
                    padding-left: 32px;
                    width: 100%;
                }
                & .searchImgg {
                    position: absolute;
                    color: #93a6c4;
                    top: 7px;
                    left: 7px;
                }
            }

            & .currency_search_list {
                height: 300px;

                & .dropList {
                    border: 1px solid transparent;
                    color: #fff;
                    background-color: #212632 !important;
                    border-radius: 25px;
                    padding: 5px 10px !important;
                    &:hover {
                        border-color: #fff;
                    }
                    &.selected{
                        background-color:#0e95fa !important;
                    }
                    & .form-check-input{
                        -bs-form-check-bg: #6A798C !important;
                        background-color: #6A798C !important;
                        border: 4px solid #2F364C !important;
                    }
                    & .form-check-input:checked[type=radio]{
                        --bs-form-check-bg-image:none !important;
                        background-color: #0e95fa !important;
                        border: 4px solid #ffffff !important;
                    }
                    & img{
                        height:25px;
                    }
                }
                & .radio-btn{
                    & .radio-outer{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #2F364C;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        & .radio-inner{
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: #6A798C;
                        }
                    }
                    &.radio-checked{
                        & .radio-outer{
                            background-color: #fff;
                        }
                        & .radio-inner{
                            
                            background-color: #0e95fa;
                        }
                    }
                }
            }
            & .dropFooter {
                background-color: #2e3443;
                height: 56px;
                padding: 20px;
                border-radius: 0px 0px 5px 5px;

                & .footerToggle .form-check-input {
                    background-color: #242937;
                    height: 18px;
                    width: 38px;
                    border-radius: 3px;
                    border: 0;
                }
            }
        }
    }

    & .add_txt {
        font-size: 13px;
    }
    & .fiat_lists {
        & li {
            color: #6a798c;
            font-size: 14px;
        }
    }

    & .swap_sec {
        & .swap_row {
            & .swap_select {
                top: -5px;
                right: 0;
            }
        }

        & .swap_line {
            position: relative;

            & .divider-line {
                position: absolute;
                width: 83%;
                height: 1px;
                background-color: #292d5b;
                top: 19px;
                border-radius: 28px;
            }
            & button {
                border: 1px solid #3e3e3e;
                height: 40px;
                width: 40px;
                position: relative;

                & img {
                    height: 18px;
                    width: 18px;
                    object-fit: contain;
                }
            }
        }

        & .total_deposit {
            & .deposit_bln_list {
                border-top: 1px solid #31416b;
            }
        }

    }

    & .crypto_row{
        background: #191d27;
        border-radius: 12px;
        overflow: hidden;

        & .table>:not(caption)>*>* {
            background: transparent;
            color: #fff;
        }
    
        & table{
            tbody{
                td{
                    text-align: center;
                    border-bottom: 1px solid #383d73;
                    white-space: nowrap;
                }
            }
        }

    }

    & .flat_row {

    background-color: #191d27;
    border-radius: 12px;

        & table {

            & thead{
                & th{
                     text-align: start !important;

                }
            }
            tbody{
                td{
                    text-align: center !important;
                    white-space: nowrap;

                }
            }
        }
    }

    & .table_sec {
            & .table>:not(caption)>*>* {
                background: transparent ;
                color: #fff;
            }

            & table {

                & thead{
                    & th{
                         background: #191d27 !important;
                         text-align: center;
                         border-bottom: none;
                         font-weight: 400;

                        &:first-child{
                            border-radius: 30px 0 0 30px;
                        }
                        &:last-child{
                            border-radius: 0px 30px 30px 0px;  
                        }
                    }
                }
                tbody{
                    td{
                        text-align: center;
                        border-bottom: 1px solid #383d73;
                        white-space: nowrap;
                    }
                }
            }
        
    }

   & .data_not_found{
       border-bottom: none;
    }

    & .deposit_issues_bx{
        box-shadow: 0 0 3px #060606;
        border-radius: 12px;
        overflow: hidden;

        & .modal-content{
           background: #191d27 !important;
        }

        & .deposit_head{
            background: #202733;

        & .btn-close{
            height: 20px;
            width: 20px;
            color: #fff;
            background: #2c3648;
           background-image: none;
            box-shadow: 0 0 3px #191919;
            display: flex;
            justify-content: center;
            align-items: center;
        }      
    }
        
        & .deposit_body p{
            font-weight: 500;
        }
    }

//   mobile_tab_btn

   & .drop_mb_tab{
        color: #B2B6C5 !important;
        background-color: #1E222D;
        margin-block: 10px;
        padding: 9px 9px;
        border: 1px solid #2E3443;

        & .dropIcon {
            height: 32px;
            width: 32px;
            display: flex;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            background: #202733;
            box-shadow: 0 0 2px #969696;

            & img{
                height: 16px;
                width: 100%;
                object-fit: contain;
            }
        }

        &.active {
            background-image: linear-gradient(90deg, #0e95fa 0%, #99d3ff 100%) !important;
            color: white !important;
        
        & .dropIcon {
            background: #fff;
        }
    }
  }


   
}


@media only screen and (max-width:768px){
    .text-sm-responsive{
        overflow-x: auto;
    }
    

    .wallet_sec{

        & .wallet_balance_sec {
            font-size: 14px;
 
      
            & .total_balance_bx {

              & .balance_box {
      
                & .balance_icon {
                  & img {
                    height: 19px !important;
                    width: 19px !important;
                  }
                }
      
                & .balance_content {
                  & .balance_head {
                    font-size: 11px;
                  }
                  & .balance_txt {
                    font-size: 10px;
                  }
                }
              }
            }

          }

          & .gift_box {

            & span{
                font-size: 14px;
            }
          }

          & .coins_box{
            font-size: 14px;
          }

          & .crypto_tabPane{
              font-size: 14px;
          }
          & .currency_list{

            & ul{
                font-size: 14px;
            }
          }

          & .fiat_tabPane{
            font-size: 14px;

            & .fiat_lists li {
                font-size: 12px !important;
            }
          }

          & .withdraw_row {
            font-size: 14px;
          }
          & .swap_row{
            font-size: 14px;
          }
          & .transactions_row{
            font-size: 14px;

            & .transaction_table{
                & table{
                    font-size: 12px;
                }
            }
          }
          & .rollover_row {
            font-size: 14px;

            & .rollover_table{
                & table{
                    font-size: 12px;
                }
            }
          }

          & .betHistory_row {
            font-size: 14px;

            & .betHistory_table{
                & table{
                    font-size: 12px;
                }
            }
          }

          & .deposit_issues_bx{
            font-size: 14px;
          }
          & .barcode_address{

            & .bar_img {
    
                & img{
                    height: 76px;
                    object-fit: contain;
                }
            }
        }
    }
    
}

.modal-backdrop.show{
    z-index: 99;
}

.withdraw_row{
    & .crypto-icon{
        width: 25px;
        height: 25px;
    }
    & .withdraw-available{
        border-bottom: 1px solid; /* Use at least 2px for better visibility */
        border-image-source: linear-gradient(90deg, rgba(57, 145, 210, 0) 0%, #1D4A6C 47.5%, rgba(57, 145, 210, 0) 100%);
        border-image-slice: 1; /* Required for border-image to work */
    }
    & #withdrawConfirmationModal{
        & .modal-dialog{
            & .modal-content{
                background-color: #191D27 !important;
                & .modal-header{
                    background-color: #202733;
                    border-bottom: 0 !important;
                    & .btn-close{
                        filter: invert(1);
                    }
                }
                & .modal-body{
                    & .confirm-tabs{
                        border-bottom: 1px solid  #1D4A6C;
                        & .copy-text{
                            background-color: #2F364C;
                            border-radius:25px;
                            overflow-wrap: anywhere;
                            padding: 2px 7px;
                        }

                        & h6{
                            color: #6A798C;
                            font-weight: 500;
                            font-size: 13.1px;
                        }
                        & p{
                            font-weight: 500;
                            font-size: 15px;
                        }
                    }
                    & .withdraw-wrap-outer{
                        background-color: #202733;
                        border-radius: 0px 0px 10px 10px;
                        & .withdraw-wrap1{
                            border-bottom: 1px solid #1D4A6C;
                        }
                    }
                    
                    & .amount-fees{
                        background-color: #2F364C;
                        padding: 10px 5px;
                        text-align: center;
                        border-radius: 10px 10px 0px 0px;
                    }
                    & .withdraw-wrap{
                        & h6{
                            font-weight: 500;
                            font-size: 13.1px;
                        }
                        & p{
                            font-weight: 500;
                            font-size: 15px;

                        }
                    }
                    
                    & .confirm-btn {
                        background-color: #0E95FA;
                        border-radius: 25px;
                        font-weight: 600;
                        font-size: 12px;
                        color: #fff;
                        width: 100%;
                        padding: 11px 41px 11px 41px;
                        border: none;
                    }


                    & .cancel-btn {
                        border-radius: 25px;
                        background-color: #2F364C;
                        font-weight: 600;
                        font-size: 12px;
                        color: #6A798C;
                        width: 100%;
                        padding: 11px 41px 11px 41px;
                        border: none;
                    }
                }
            }
            &.modal-lg{
                --bs-modal-width: 720px !important;
            }
        }
    }
}

.min_max_tab{
    & .min-max-button{
        background: #2f364c;
        color: #6a798c;
        &.min-max-active{
            background-color: #0e95fa;
            color: #fff;
        }
    }
}

.withdraw_sec{
    & .preview-disable{
        background: rgb(133, 188, 229) !important;
    }
}