.modalSection {
  & .modal-content {
    background-color: $theme10;
    border-radius: 10px;
    & .modal-header {
      background-color: $theme8;
      display: flex;
      align-items: center;
      border: 0;
      padding: 10px 10px;
      @include sm {
        padding: 10px 20px;
      }
      & .modal-title {
        font-size: 15px;
        font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        @include sm {
          font-size: 18px;
        }
        & span {
          width: 6px;
          height: 12px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            height: 100%;
            width: 100%;
          }
        }
      }
      & .btn-close {
        filter: invert(1);
        color: white;
        height: 11px;
        width: 11px;
        font-size: 11px;
        box-shadow: none;
        @include sm {
          height: 15px;
          width: 15px;
          font-size: 15px;
        }
      }
      & .coniSearchBar {
        background-color: $theme1;
        border: none;
        position: relative;
        width: 100%;
        border-radius: 5px;
        & input {
          background-color: $theme1;
          border: none;
          box-shadow: none;
          outline: none;
          border-radius: 5px;
          color: $theme5;
          font-size: 12px;
          height: 35px;
          padding-left: 40px;
          padding-right: 10px;

          &::placeholder {
            color: $theme5;
            font-size: 12px;
          }
        }
        & .searchIcon {
          height: 15px;
          width: 15px;
          display: flex;
          left: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          & img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    & .modal-body {
      background: var(--Gray-Mneu-button-Color, #202733);
      padding: 10px 10px;
      @include sm {
        padding: 10px 20px;
      }
      & .dblrackback {
        background-image: url(../images/usdt-bg.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 100%;
        width: 100%;
        padding: 10px;
        & .usdt-logo{
          font-weight: 700;
          font-size: 20px;
          text-align: center;
          text-shadow: 3px 3px 0px #00000040;
          color: #fff;
        }
        & .dblImage {
          height: 45px;
          display: flex;
          align-items: center;
          // @include sm {
          //   height: 40.69px;
          //   width: 125px;
          // }
        }
        & .dblheaidng {
          font-size: 12px;
          color: $theme5;
          text-align: center;
          margin-top: 0px;
        }
        & .dblValue {
          background-color: #191D27;
          padding: 1px 85px;
          border-radius: 25px;
          font-size: 15px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #C2FB01;
          font-weight: 500;
          border: 1px solid  #6A798C;
          @include sm {
            font-size: 20px;
            background-color: $theme8;
          }
          & .valueimg {
            height: 17px;
            width: 17px;
            display: flex;
            margin-right: 10px;
           
          }
        }
        & .registernowBtn {
          height: 32px;
          background-color: #0E95FA;
          border: 0;
          padding-inline: 35px;
          border-radius: 25px;
          font-size: 14px;
          font-weight: 600;
          color: white;
          @include sm {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      & .lockeddbl {
        background-color: #191D27;
        padding: 5px 20px;
        border-radius: 10px;
        margin-block: 10px;
        & .loackheading {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #6A798C;
          font-weight: 600;
          & .lockImg {
            height: 16px;
            margin-right: 15px;
            display: inline-flex;
          }
        }
        & .loackValue {
          font-size: 12px;
          color: #6A798C;
          font-weight: 600;
          & span {
            color: white;
          }
        }
        & .loacksubvalue {
          font-size: 12px;
          color: #6A798C;
        }
      }
      & .claimHeading {
        font-size: 13px;
        color: white;
        font-weight: 600;
        text-align: center;
        @include sm {
          font-size: 15px;
        }
      }
      & .viewDetails {
        background-color: #191D27;
        border-radius: 10px;
        padding: 15px;
        & .detailsSection {
          padding-bottom: 8px;
        }
        & .viewDetailsheading {
          font-size: 14px;
          color: #fff;
          font-weight: 400;
        }
        & .moreDetailsbtn {
          color: $theme3;
          font-size: 13px;
          font-weight: 400;
        }
        & .mainDivBtn {
          & li {
            width: 50%;
          }
          & .casinoBtn {
            height: 38px;
            background-color: #0E95FA;
            border: 0;
            padding-inline: 35px;
            border-radius: 25px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            width: 100%;
          }
        }
      }
      & .registernowBtn {
        height: 38px;
        background-color: #0E95FA;
        border: 0;
        padding-inline: 35px;
        border-radius: 25px;
        font-size: 14px;
        font-weight: 500;
        color: white;
      }
      & .bounesHistory {
        background-color: #15191F;
        height: 38px;
        border: 0;
        padding-inline: 35px;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 500;
        color: #B2B6C5;
        & img{
          height: 15px;
        }
        &.unlockDbl {
          color: $theme3;
          font-weight: 400;
        }
        & .icon {
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          & img {
            width: 100%;
            height: 100%;
          }
        }
      }
      & .BounseTable {
        & table {
          border-collapse: separate;
          border-spacing: 0 5px;
          & > * {
            border: 0;
          }
          & thead {
            & th {
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              white-space: nowrap;
              color: $theme5;
              padding: 7px 10px;
              border: 0;
              background-color: transparent;

              @include lg {
                // font-size: 14px;
                background-color: transparent;
              }
              &:first-child {
                border-radius: 3px 0 0 3px;
              }
              &:last-child {
                border-radius: 0 3px 3px 0;
              }
            }
          }
          & tbody {
            & tr {
              background-color: #191D27;
              // &:nth-child(even) {
              //   background-color: transparent;
              // }
            }
            & td {
              font-size: 12px;
              font-weight: 500;
              background-color: transparent;
              white-space: nowrap;
              color: $theme5;
              padding: 7px 14px;
              border: 0px;
              vertical-align: middle;

              & .amount {
                color: #C2FB01;
                display: flex;
                align-items: center;
                justify-content: end;
                & .amountImg {
                  height: 20px;
                  margin-right: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  & img {
                    height: 100%;
                    width: 100%;
                  }
                }
              }
              @include lg {
                // font-size: 14px;
              }
              &:first-child {
                border-radius: 25px 0 0 25px;
              }
              &:last-child {
                border-radius: 0 25px 25px 0;
              }
              & .gameImg {
                height: 20px;
                width: 20px;
                overflow: hidden;
                border-radius: 2px;
                @include lg {
                  display: none;
                }
              }
              & .gameName {
                color: white;
              }
              &.activeVal {
                color: $theme3;
              }
            }
          }
          & .mobHiddenTableData {
            @include max-lg {
              display: none;
            }
          }
        }
        @include lg {
          margin-top: 12px;
        }
      }
      & .bounseRouleHeading {
        font-size: 14px;
        line-height: normal;
        color: $theme5;
        font-weight: 500;
        @include sm {
          font-size: 15px;
        }
      }
      & .bounsCard {
        background-color: $theme8;
        border-radius: 5px;
        padding: 5px;

        @include sm {
          padding: 10px;
        }
        & .bounseImge {
          height: 90px;
          border-radius: 5px;
          overflow: hidden;
          @include sm {
            height: 100px;
          }
        }
        & .subheadingDeposite {
          font-size: 10px;
          color: $theme5;
          font-weight: 400;
          @include sm {
            font-size: 13px;
          }
        }
        & .headingDeposite {
          font-size: 9px;
          color: white;
          font-weight: 500;
          @include sm {
            font-size: 13px;
          }
        }
      }
      & .watchImg {
        height: 80px;
        width: 70px;
        @include sm {
          height: 90px;
          width: 80px;
        }
      }
      & .timerSection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;

        & .timerBox {
          background-color: $theme1;
          border: 1px solid $theme8;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          height: 55px;
          width: 55px;
          & .timerValue {
            font-size: 13px;
            line-height: normal;
            font-weight: 500;
            color: white;
            @include sm {
              font-size: 15px;
            }
          }
          & .timerName {
            font-size: 11px;
            line-height: 15px;
            font-weight: 400;
            color: $theme5;
            @include sm {
              font-size: 13px;
            }
          }
        }
      }
      & .positionCard {
        background-color: $theme8;
        border-radius: 5px;
        padding: 10px;
        & .borderRight {
          border-right: 0.6px solid $theme5;
        }
        & .positionHeading {
          font-size: 10px;
          line-height: 19.5px;
          color: $theme5;
          text-align: center;
          @include sm {
            font-size: 14px;
          }
        }
        & .positionValue {
          font-size: 12px;
          line-height: 19.5px;
          color: white;
          font-weight: 500;
          text-align: center;
          @include sm {
            font-size: 14px;
          }
        }
      }
      & .heaidngoneRace {
        font-size: 10px;
        line-height: normal;
        color: $theme5;
        font-weight: 400;
        @include sm {
          font-size: 14px;
        }
      }
      & .Raceheaing {
        font-size: 15px;
        font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        @include sm {
          font-size: 18px;
        }
      }
      & .racepointCard {
        background-color: $theme1;
        border-radius: 5px;
        padding: 30px 20px;
        & .raceStatesCard {
          background-color: #d9d9d9;
          padding: 10px;
          border-radius: 10px;
          height: 50px;
          width: 50px;
          font-size: 20px;
          color: black;
          font-weight: 600;
          @include sm {
            height: 70px;
            width: 70px;
            border-radius: 15px;
            font-size: 28px;
          }
        }
      }
      & .countDownheading {
        font-size: 11px;
        color: $theme5;
        font-weight: 400;
        padding-bottom: 7px;
        width: 100%;
        @include sm {
          font-size: 14px;
        }
        & .reward {
          color: $theme6;
          font-weight: 500;
        }
      }
      & .confirmmodalTitle {
        font-size: 18px;
        color: white;
        font-weight: 600;
        text-align: center;
        @include sm {
          font-size: 20px;
        }
      }
      & .confirmsubTitle {
        font-size: 13px;
        color: $theme5;
        font-weight: 400;
        text-align: center;
        @include sm {
          font-size: 16px;
        }
      }
      & .btn-width {
        width: 150px;
      }
      & .chatRules {
        font-size: 13px;
        line-height: normal;
        color: $theme5;
        font-weight: 400;
      }

      & .formLabel {
        font-size: 14px;
        line-height: normal;
        color: $theme5;
        & .formValue {
          color: white;
        }
      }
      & .inputGroup {
        background-color: $theme8;
        border-radius: 5px;
        height: 40px;
        margin-top: 5px;
        @include sm {
          height: 55px;
        }
        & .borderRight {
          border-right: 0.5px solid $theme5;
        }
        & input {
          background-color: transparent;
          border: none;
          font-size: 14px;
          // height: 100%;
          color: $theme5;
          border-radius: 0;
          &::placeholder {
            border: none;
            font-size: 14px;
            color: $theme5;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      & .modalTextarea {
        background-color: $theme8;
        border-radius: 5px;
        padding: 10px;
        border: none;
        font-size: 14px;
        color: $theme5;
        height: 70px !important;
        @include sm {
          height: 90px !important;
        }
        &::placeholder {
          border: none;
          font-size: 14px;
          color: $theme5;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      & .coinHeading {
        font-size: 20px;
        color: white;
        font-weight: 600;
        text-align: center;
        @include sm {
          font-size: 25px;
        }
        & span {
          color: $theme14;
        }
      }
      & .coinBtn {
        background-color: $theme14;
      }
      & .asstesPortfoliotab {
        background-color: $theme8;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 2px;
        height: 100%;
        gap: 2px;

        & .tabsBtn {
          font-size: 14px;
          color: $theme5;
          padding: 8px 30px;
          height: 100%;
          text-decoration: none;
          border: 0.5px solid transparent;
          border-radius: 5px;
          display: flex;
          align-items: center;
          text-align: center;
          &.active {
            border: 0.5px solid $theme5;
          }
        }
      }
      & .asstesTabsmain {
        & .asstesportfoloioTabls {
          padding: 8px;
          border-radius: 5px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: $theme1;
            border-color: $theme6;
          }
          & .headingPortfolio {
            font-size: 13px;
            color: white;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 15px;
            @include sm {
              font-size: 15px;
            }
            & .icon {
              width: 15px;
              height: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
          & .value {
            font-size: 13px;
            color: white;
            font-weight: 600;
            @include sm {
              font-size: 15px;
            }
          }
        }
      }
      & .spicalBounsCard {
        padding: 70px 40px;
        & .imgSpicalBouns {
          height: 120px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include sm {
            height: 150px;
            width: 150px;
          }
          & img {
            height: 100%;
            width: 100%;
          }
        }
        & .spicalbounseHeading {
          font-size: 14px;
          color: $theme5;
          font-weight: 400;
          text-align: center;
          margin-top: 3px;
          @include sm {
            font-size: 16px;
          }
        }
      }

      &.depositBounsModal {
        & .depositeheading {
          font-size: 15px;
          font-weight: 500;
          color: white;
          display: flex;
          align-items: center;
          @include sm {
            font-size: 18px;
          }
          & .quetionImg {
            display: inline-flex;
            align-items: center;
            text-align: center;
            -webkit-transform: translateZ(0); /* webkit flicker fix */
            -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
            position: relative;
            margin-left: 10px;
            & img {
              height: 17px;
              width: 17px;
            }
            .tooltip {
              left: 50%;
              width: max-content;
              max-width: 250px;
              text-align: center;
              background: $theme10;
              border-radius: 5px;
              bottom: 100% !important;
              transform: translateX(-50%) translateY(-10px);
              color: $theme5;
              font-size: 8px;
              display: block;
              margin-bottom: 15px;
              opacity: 0;
              padding: 10px;
              pointer-events: none;
              position: absolute;
              transition: all 0.25s ease-out;
              box-shadow: 2px 2px 6px rgba(0, 0, 10, 0.5);

              & .toltipContent {
                color: $theme5;
                font-size: 8px;
                line-height: normal;
                text-align: left;
                @include sm {
                  font-size: 10px;
                }
              }
              &:before {
                bottom: -20px;
                content: " ";
                display: block;
                height: 20px;
                left: 0;
                position: absolute;
                width: 100%;
              }
              &:after {
                border-left: solid transparent 10px;
                border-right: solid transparent 10px;
                border-top: solid $theme10 10px;
                bottom: -10px;
                content: " ";
                height: 0;
                left: 50%;
                margin-left: -13px;
                position: absolute;
                width: 0;
              }
            }
            &:hover {
              .tooltip {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }
        }
        & .moreDetails {
          border: none;
          background-color: transparent;
          font-size: 14px;
          color: $theme6;
          font-weight: 500;
          text-decoration: underline;
          display: flex;
          align-items: center;
          & .arrow {
            height: 10px;
            width: 8px;
            display: flex;
            margin-left: 10px;
            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
        & .depositCard {
          background-color: transparent;
          border-radius: 5px;
          padding: 10px;
          transition: all 0.3s ease-in-out;
          &.active {
            background-color: $theme8;
          }
          &:hover {
            background-color: $theme8;
          }

          & .bonusHeading {
            font-size: 12px;
            font-weight: 500;
            color: $theme5;
            @include sm {
              font-size: 14px;
            }
          }
          & .bonusValue {
            font-size: 12px;
            font-weight: 600;
            color: white;
            @include sm {
              font-size: 14px;
            }
          }
        }
        & .bonusheadingHunt {
          font-size: 14px;
          font-weight: 500;
          color: $theme5;
          @include sm {
            font-size: 14px;
          }
          & span {
            font-weight: 600;
            color: white;
          }
        }
        & .huntImg {
          width: 100px;
          height: 70.4px;
        }
        & .spinwheel {
          height: 100px;
          width: 100px;
        }
        & .bonusSmall {
          font-size: 12px;
          color: $theme5;
          text-align: end;
        }
        & .registernowBtn {
          height: 38px;
          background-color: $theme3;
          border: 0;
          padding-inline: 35px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: white;
          border-bottom: 3px solid $theme12;
          @include sm {
            font-size: 16px;
            font-weight: 600;
            height: 43px;
          }
        }
        & .bounesHistory {
          background-color: $theme8;
          border-bottom: 3px solid $theme1 !important;
          height: 38px;
          border: 0;
          padding-inline: 35px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: white;
          width: 100%;
          &.unlockDbl {
            color: $theme3;
            font-weight: 400;
          }
          @include sm {
            font-size: 16px;
            font-weight: 600;
            height: 43px;
          }
          & .icon {
            height: 18px;
            width: 18px;
            display: flex;
            align-items: center;
            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    & .modal-footer {
      justify-content: left;
      .onOffBtn {
        display: flex;
        align-items: center;
        & .form-check-input {
          background-color: $theme8;
          height: 15.4px;
          width: 33.44px;
          border-radius: 5px;
          margin-bottom: 5px;
          padding: 12px;
          border: none;
          &:focus {
            border: 0;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .form-switch .form-check-input {
        background-image: url(../images/portfolioToggle.png) !important;
        height: 15px;
        width: 45px;
        padding: 5px;
        background-repeat: no-repeat;
      }
      .hideSmall .form-check-input {
        background-image: url(../images/hideToggle.png) !important;
        height: 15px;
        width: 45px;
        padding: 5px;
        background-repeat: no-repeat;
      }
      .toggle-div {
        display: flex;
        align-items: center;
        gap: 10px;
        & .label-txt {
          font-size: 13px;
          line-height: 16px;
          text-wrap: nowrap;
          color: $theme5;
          font-weight: 500;
        }
      }
    }
  }
}

.unlike-game {
  filter: grayscale(1);
}

.modalDropDwonn {
  & button {
    background-color: $theme8;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: white;
    font-weight: 500;
    gap: 40px;
    width: 145px;
    &:first-child:active {
      background-color: $theme8;
      color: $theme5;
    }
    &.btnClour {
      color: $theme5;
    }
    &.active {
    }
    &:focus-visible {
      border: none;
      background-color: $theme8;
      outline: none;
      box-shadow: none;
      box-shadow: none;
    }
    &.show {
      background-color: $theme8;
    }
    &:hover {
      background-color: $theme8;
    }
    &::after {
      display: none;
    }
    & .icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & .rightArrow {
      width: 7.33px;
      height: 11.54px;
      display: flex;
      align-items: center;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  & .dropdown-menu {
    background-color: $theme8;
    border-radius: 5px;
    padding: 5px;
    border: none;
    margin-top: 15px;
    width: 9rem;
    // display: flex;
    // flex-direction: column;
    // gap: 2px;
    & .dropdown-item {
      font-size: 13px;
      color: $theme5;
      font-weight: 400;
      padding: 7px 10px;
      border-radius: 5px;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        border-color: $theme6;
        background-color: transparent;
      }
      &.active {
        background-color: $theme1;
      }
    }
  }
}

.providerDropdown {
  position: relative;
  & button {
    align-items: center;
    background-color: $theme8;
    border-color: #212632;
    border: 1px solid $theme8;
    border-radius: 5px;
    color: $theme5;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    min-width: 150px;
    padding: 8px 16px;
    & span {
      color: $theme6;
      margin-left: 7px;
    }
    &:first-child:active {
      background-color: $theme8;
      color: $theme5;
      border-color: transparent;
    }
    &:focus-visible {
      border: none;
      background-color: $theme8;
      outline: none;
      box-shadow: none;
      box-shadow: none;
      border-color: transparent;
    }
    &.show {
      background-color: $theme8;
      border-color: transparent;
    }
    &:hover {
      background-color: $theme8;
      border-color: transparent;
      color: $theme5;
    }
  }
  & .dropdown-menu {
    background-color: $theme8;
    border-radius: 5px;
    padding: 5px !important;
    border: none;
    margin-top: 5px;
    & .dropdown-item {
      padding: 0 5px;
      &:hover {
        background-color: transparent;
      }
      .dropdownLabel {
        width: 100%;
        cursor: pointer;
        &.active {
          & .dropdownCheckbox {
            background-color: #15191f;
            &::before {
              display: flex;
            }
          }
        }
        & .dropdownCheckbox {
          height: 17px;
          width: 17px;
          margin: 0px 8px 0 0;
          min-width: 17px;
          display: flex;
          border: 2px solid $theme6;
          border-radius: 4px;
          cursor: pointer;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            height: 10px;
            width: 7px;
            border-right: 3px solid $theme6;
            border-bottom: 3px solid $theme6;
            border-radius: 2px;
            display: none;
          }
        }

        & .dropdownTxt {
          color: white;
          font-size: 14px;
        }

        & .dropdownValue {
          background-color: $theme1;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 11px;
        }
      }
    }
  }
}

.login-toast-container {
  background: linear-gradient(to bottom, #002789, #000d2c);
  width: 320px;
  height: 60px;
  // border-radius: 12.14px;
  color: white;
  display: flex;
  gap: 12px;
  padding-left: 30px;
  gap: 20px;
  align-items: center;

  & .login-toast-image {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  & .login-toast-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    & .login-toast-Congratulations {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0%;
      text-align: center;
      text-transform: uppercase;
    }
    & .login-toast-msg {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0%;
      text-align: center;
    }
  }
}

.Toastify__toast {
  background: linear-gradient(to bottom, #002789, #000d2c) !important ;
  padding: 0;
}
.Toastify__toast-body {
  padding: 0;
}
